import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import withConfig from '../data/withConfig';

const HeaderWrapper = styled.header`
  position: absolute;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  padding: 0 5%;
  align-content: center;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const HeaderText = styled.h1`
  margin: 0;
  font-size: ${({ isMobile }) => (isMobile ? '30px' : '35px')};
  color: ${({ scrolled, isMobile }) => (scrolled || isMobile ? '#fff' : '#000')};
  font-family: Lobster, serif;
  font-weight: 400;
  transition: font-color 420ms;
`;

function Header({ header, isMobile, scrolled }) {
  return (
    <HeaderWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
          <HeaderText isMobile={isMobile} scrolled={scrolled}>
            {header.title}
          </HeaderText>
        </Link>
      </div>
    </HeaderWrapper>
  );
}

Header.propTypes = {
  header: PropTypes.shape().isRequired,
  isMobile: PropTypes.bool.isRequired,
  scrolled: PropTypes.bool.isRequired,
};

export default withConfig(Header);
