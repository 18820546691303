import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import Form from './Form';
import TopAnnouncement from '../announcement/TopAnnouncement';

const Page = styled.div`
  padding-top: 60px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #f8f8f8;

  @media (max-width: 900px) {
    padding-top: 100px;
  }
`;

const Title = styled.div`
  width: 500px;
  text-align: center;
  max-width: 90%;

  h2 {
    margin: 10px 0;
  }

  img {
    margin: 0;
  }
`;

const TitleText = styled.div`
  width: 100%;
  transform: ${({ sent }) => (sent ? 'translateY(200%)' : 'translateY(0)')};
  transition: transform 1s;
`;

const FormWrapper = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 950px;
  max-width: 100%;
`;

const PlaneImage = styled.img`
  transform: ${({ sent }) =>
    sent ? 'translate(200%, -300%)' : 'translate(0, 0)'};
  opacity: ${({ sent }) => (sent ? 0 : 1)};
  transition: transform 600ms, opacity 600ms;
`;

function ContactPage({ state }) {
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  const [selected, setSelected] = useState(state ? state.option : '');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    visitors: '',
    age: '',
    date: '',
  });

  const handleFormChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = e => {
    console.log('formValues', formValues);
    e.preventDefault();
    setLoading(true);
    axios
      .post('/api/send', {
        // name,
        // email,
        // phone,
        formValues,
        comments: `Option ${selected + 1}`,
      })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(() => {
        setLoading(false);
        setSubmitted(true);
      });
    setLoading(false);
    setSubmitted(true);
  };

  const formProps = {
    // name,
    // setName,
    // email,
    // setEmail,
    // phone,
    // setPhone,
    selected,
    setSelected,
    loading,
    setLoading,
  };

  const title = submitted ? 'Thank you' : 'Book Now';
  const text = submitted
    ? ["Thank you for getting in touch we'll get back to you shortly"]
    : [
        "We'd love to hear from you. Fill in the form below and we'll get back to you shortly, to confirm your visit!",
      ];

  const contactText = submitted ? (
    ''
  ) : (
    <>
      <p style={{ margin: 0 }}>
        If you have any additional questions please do email us at:{' '}
        <strong>info@downswoodalpacas.co.uk</strong>
      </p>
      <p style={{ margin: 0, marginBottom: 20 }}>
        or phone us on: <strong>01380 812174</strong>
      </p>
    </>
  );

  // Ln/000016929 01380 812174
  return (
    <>
      <Page>
        <FormWrapper onSubmit={handleSubmit}>
          <Title>
            <PlaneImage
              sent={submitted}
              src="https://image.flaticon.com/icons/svg/941/941565.svg"
              alt=""
              height={50}
            />
            <TitleText sent={submitted}>
              <h2>{title}</h2>
              {text.map(each => (
                <p>{each}</p>
              ))}
              {contactText}
            </TitleText>
          </Title>
          {!submitted && (
            <div style={{ border: '1px solid #ddd', padding: 64, background: 'white', borderRadius: 16 }}>
              <Form
                {...formProps}
                formValues={formValues}
                setFormValues={handleFormChange}
              />
            </div>
          )}
        </FormWrapper>
      </Page>
    </>
  );
}

ContactPage.propTypes = {
  state: PropTypes.shape(),
};

ContactPage.defaultProps = {
  state: { option: null },
};

export default ContactPage;
