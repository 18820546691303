import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const Article = styled.section`
  box-sizing: border-box;
  background: #eaeaea;
  padding: 0 5%;
  position: fixed;
  top: 0;
  height: 50px;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;

  div {
    background: orange;
    margin-right: 32px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0 16px;
    font-weight: 800;
  }

  p {
    padding-top: 4px;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 900px) {
    height: 100px;

    div {
      margin-right: 8px;
    }
    p {
      font-size: 12px;
    }
  }
`;

// eslint-disable-next-line
const TopAnnouncement = () => (
  <Article>
    <div>
      <p>FOR SALE</p>
    </div>

    <p>
      We have a selection of alpacas for sale of various ages and colours.
      Please contact us for details: info@downswoodalpacas.co.uk or 01380 812174
    </p>
  </Article>
);

export default TopAnnouncement;
