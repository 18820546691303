import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactPage from '../components/contact-page/ContactPage';
import Header from '../components/contact-page/Header';

const SecondPage = ({ location }) => (
  <Layout>
    <SEO title="contact form" />
    <Header />
    <ContactPage state={location.state} />
  </Layout>
);

export default SecondPage;
