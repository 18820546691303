import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 15px 0;
  padding: 0 15px;
  width: 100%;
`;

const Text = styled.div`
  margin: 15px 0;
  width: 100%;
`;

const Title = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #ddd solid;
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
`;

const Body = styled.div`
  padding: 20px;
  width: 100%;
`;

const data = [
  {
    title: 'PRACTICALITIES',
    text: 'Children over 12 yrs may lead an alpaca on their own, but all children between the ages of 8 - 16 must be supervised by an adult. Children 8 - 12yrs may accompany an adult on a walk but may not lead an alpaca. Unfortunately we can only cater for children of 8 years and over. Please wear sturdy shoes or boots - alpacas have sharp toes and may accidentally tread on your foot!  Conditions can be wet underfoot and the ground is uneven. Should you have any mobility problems or want to bring a wheel chair we can tailor a visit for you.',
  },
  {
    title: 'IMPORTANT!',
    text: 'Please arrive well before the due time for walking as the experience will start promptly. Visitors will be asked to check in and sign a disclaimer form then will be given a safety briefing and demonstration before  leading the alpacas. Our alpacas are very precious to us and we expect everybody to treat them kindly. Booking is strictly by appointment, payment in advance.',
  },
  {
    title: 'CANCELLATION POLICY',
    text: 'If you wish to cancel a walk we need at least 48 hours notice. If we have less than 48 hours notice, or you don’t turn up, unfortunately your booking fee will be lost. Currently we operate a no refund policy -(except in the circumstances below where Downswood Alpacas has to cancel). Those who are unable to fulfil a booking and contact us more than 48 hours before that booking will be offered the chance to rebook on another date. Should, for any reason, Downswood Alpacas have to cancel a booking, those affected will be offered the chance to either rebook or have a full refund.',
  },
];

function Disclaimer({ title, text }) {
  const [open, setOpen] = useState(false);
  return (
    <Text>
      <Title onClick={() => setOpen(!open)}><strong><small>{title}</small></strong></Title>
      { open && <Body><small>{text}</small></Body>}
    </Text>
  );
}

function Disclaimers() {
  return (
    <Wrapper>
      { data.map(each => <Disclaimer {...each} />)}
    </Wrapper>
  );
}

export default Disclaimers;
