import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withConfig from '../data/withConfig';
import SelectTile from './SelectTile';

const TileWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 50px;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 900px) {
    grid-template-columns: 100%;
  }
`;

function OptionTiles({ offers, selected, setSelected }) {
  return (
    <TileWrapper>
      {offers.cards.map((each, index) => (
        <SelectTile
          index={index}
          {...each}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </TileWrapper>
  );
}

OptionTiles.propTypes = {
  offers: PropTypes.arrayOf().isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default withConfig(OptionTiles);
