import React from 'react';
import PT from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import Disclaimers from './Disclaimers';
import withConfig from '../data/withConfig';
import OptionTiles from './OptionTiles';

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const InputBox = styled.div`
  box-sizing: border-box;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 90%;
  height: 40px;
  background: #fff;
  font-size: 14px;
  margin: 10px 0;
  border: 1px #ddd solid;
  border-radius: 20px;
  padding: 0 20px;
  line-height: 14px;

  :focus {
    border: 1px solid #008489;
    outline: none;
  }
`;

const Button = styled.button`
  width: 30%;
  height: 40px;
  border-radius: 20px;
  margin: 30px 10px;
  font-size: 16px;
  border: 1px solid #008489;
  background: #008489;
  color: #fff;
  outline: none;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 75%;
  }
`;

function Form({ formValues, setFormValues, selected, setSelected, loading }) {
  return (
    <>
      <InputWrapper>
        <InputBox>
          <small>Name</small>
          <Input
            type="text"
            placeholder="Your name"
            value={formValues.name}
            onChange={e => setFormValues('name', e.target.value)}
          />
        </InputBox>
        <InputBox>
          <small>Email</small>
          <Input
            type="email"
            placeholder="Your email"
            required
            value={formValues.email}
            onChange={e => setFormValues('email', e.target.value)}
          />
        </InputBox>
        <InputBox>
          <small>Phone</small>
          <Input
            type="tel"
            placeholder="Your phone number"
            value={formValues.phone}
            onChange={e => setFormValues('phone', e.target.value)}
          />
        </InputBox>
      </InputWrapper>
      <InputWrapper>
        <InputBox>
          <small>Number of visitors</small>
          <Input
            type="text"
            placeholder="Number of visitors"
            value={formValues.visitors}
            onChange={e => setFormValues('visitors', e.target.value)}
          />
        </InputBox>
        <InputBox>
          <small>Age of children</small>
          <Input
            type="text"
            placeholder="Age of children"
            value={formValues.age}
            onChange={e => setFormValues('age', e.target.value)}
          />
        </InputBox>
        <InputBox>
          <small>PTeferred date</small>
          <Input
            type="date"
            value={formValues.date}
            onChange={e => setFormValues('date', e.target.value)}
          />
        </InputBox>
      </InputWrapper>
      <OptionTiles selected={selected} setSelected={setSelected} />
      <Disclaimers />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading ? (
          <div style={{ marginTop: 30 }}>
            <CircularProgress />
          </div>
        ) : (
          <Button type="submit">Send</Button>
        )}
      </div>
    </>
  );
}

Form.propTypes = {
  formValues: PT.shape({
    name: PT.string,
    email: PT.string,
    phone: PT.string,
    visitors: PT.string,
    age: PT.string,
    date: PT.string,
  }).isRequired,
  setFormValues: PT.func.isRequired,
  selected: PT.bool.isRequired,
  setSelected: PT.func.isRequired,
  loading: PT.bool.isRequired,
};

export default withConfig(Form);
