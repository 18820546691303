import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TileBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
    margin: 20px 0;
  }
`;

const Tile = styled.div`
  position: relative;
  width: 100%;
  background: ${({ selected, index }) =>
  selected === index ? '#ddd' : '#fff'};
  border: ${({ selected, index }) =>
    selected === index ? '4px solid #008489' : '1px #eaeaea solid'};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h3 {
    padding-top: 20px;
    font-size: 16px;
    text-align: center;
  }

  ul {
    font-size: 14px;
    list-style: none;
    list-style: none;
    line-height: 1.2;
    list-style-position: outside;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 25px;
    padding-bottom: 20px;
  }

  li {
    margin: 5px 0;
    text-align: justify;
    font-size: 15px;
  }

  ul li:before {
    content: '✓ ';
  }
`;

const SelectButton = styled.button`
  position: absolute;
  align-self: center;
  bottom: 10px;
  width: 50%;
  height: 30px;
  align-self: center;
  font-size: 14px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

function SelectTile({ index, selected, setSelected, info, included }) {
  const handleClick = e => {
    e.preventDefault();
    setSelected(index);
  };

  return (
    <TileBox onClick={e => handleClick(e)}>
      <Tile selected={selected} index={index}>
        <h3>{info[0]}</h3>
        <ul>
          {included.map(each => (
            <li>{each}</li>
          ))}
        </ul>
        <SelectButton>
          {selected === index ? (
            <p style={{ color: '#008489' }}>
              <strong>SELECTED!</strong>
            </p>
          ) : (
            'SELECT'
          )}
        </SelectButton>
      </Tile>
    </TileBox>
  );
}

SelectTile.propTypes = {
  index: PropTypes.number.isRequired,
  info: PropTypes.string.isRequired,
  included: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default SelectTile;
